import React from 'react';
import styled from '@emotion/styled';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import RouterTracker from '../../components/RouterTracker';
import ThemeContext from '../../context/ThemeContext';

var tinycolor = require('tinycolor2');

const TagsStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .taglist {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: ${props.theme.size.base}px;
      margin-bottom: ${props.theme.size.base}px;
      white-sapce: nowrap;
      span {
        display: inline-block;
        margin-left: ${props.theme.size.base * 1.5}px;
        padding: 0  ${props.theme.size.base * 1.5}px;
        border-radius: 999px;
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
        ${
          props.theme.dark
            ? `
            background: ${props.theme.color.secondary[40]};
            color: ${tinycolor
              .mostReadable(props.theme.color.secondary[40], [
                props.theme.color.grayscale[0],
                props.theme.color.grayscale[100],
              ])
              .toHexString()};
          `
            : `
            background: ${props.theme.color.secondary[95]};
            color: ${tinycolor
              .mostReadable(props.theme.color.secondary[95], [
                props.theme.color.grayscale[0],
                props.theme.color.grayscale[100],
              ])
              .toHexString()};
          `
        };
      }
      a {
        background: ${props.theme.color.background[100]};
        color: ${props.theme.color.foreground[0]};
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        color: ${tinycolor
          .mostReadable(props.theme.color.background[100], [
            props.theme.color.primary[75],
            props.theme.color.primary[40],
          ])
          .toHexString()};
        text-decoration: none;
        padding: 0 ${props.theme.size.base * 3}px;
        height: ${props.theme.size.base * 5}px;
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
        font-weight: 600;
        letter-spacing: 0.1em;
        cursor: pointer;
        transition: color 450ms ease, background 450ms ease;
        &:hover {
          background: ${props.theme.color.background[80]};
          color: ${tinycolor
            .mostReadable(props.theme.color.background[80], [
              props.theme.color.primary[85],
              props.theme.color.primary[25],
            ])
            .toHexString()};
        }
      }
    }
  }
`
);

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const breakpoints = useBreakpoint();
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <TagsStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <Helmet title={`Tags | ${title}`} />
          <div className='body typesetter'>
            <h1 className='title'>Tags</h1>
            <ul className='taglist'>
              {group.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue}
                    <span>{tag.totalCount}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </TagsStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
